<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Reseller Info</span>
        </span>
        <div class="text-nowrap order-md-last">
          <router-link :to="'/jual/pelanggan/reseller/edit/'+state.id" class="btn px-0 d-inline-block">
            <i class="fas fa-pencil-alt text-light"></i>
          </router-link>
        </div>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu" v-if="state.finish">
      <!-- MAIN -->
      <div id="main-content" class="col pb-5 with-fixed-sidebar">

        <div class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin bg-secondary">
          <div class="container text-center">
            <p class="h5 my-2 text-white">{{ state.data.name }}</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <p class="mb-1 small text-muted">Alamat</p>
            <p class="mb-0">{{ state.data.alamat }}
            </p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <p class="mb-1 small text-muted">Telepon/WA</p>
            <p class="mb-0">{{ state.data.phone }}</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row">
          <div class="col-12 px-0">
            <ul class="list-group rounded-0 mt-3">
              <li class="list-group-item d-flex justify-content-between align-items-center border-left-0 border-right-0 p-0">
                <a href="" class="py-2 pl-3 text-uppercase stretched-link">Riwayat Pesanan</a>
                <i class="fa fa-caret-right mr-3"></i>
              </li>
            </ul>
          </div><!-- .col -->
        </div><!-- .row -->
  
        <div class="input-group input-group-sm my-3">
          <input type="text" class="form-control" placeholder="Cari...">
          <div class="input-group-append">
            <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
          </div>
        </div>

        <div class="small text-truncate">Daftar PO Grup Aktif via Mba Oi:</div>

        <div class="alert alert-secondary bg-light mt-3 show" role="alert">Tidak ditemukan PO Grup Aktif.</div>

        <div class="row mt-3 mb-5 d-none">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="card mb-3">
              <div class="card-body p-0">
                <div class="float-left w-75 p-2 pl-3">
                  <a href="./jual-pesanan.html" class="card-title text-muted font-weight-bold d-block mb-1 mt-1 card-link stretched-link text-truncate">Snack Sehat Oi Rabu</a>
                  <p class="card-text mb-1 text-truncate">8 pembeli, 16 pcs, <span class="currency">320000</span></p>
                  <p class="card-text mb-0 text-truncate"><small class="text-muted">Rabu, 19 Mei <span class="badge badge-pill badge-secondary align-middle">RESELLER</span></small></p>
                </div>
                <div class="float-right p-2 pl-2 w-25 border-left">
                  <div class="ml-1">
                    <p class="card-text mb-0 small text-muted mt-1">Status</p>
                    <span class="badge badge-secondary align-top">SELESAI</span>
                    <p class="card-text mb-n1 small text-muted mt-1">Terkirim</p>
                    <span>8</span>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>
<script>
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      data: {},
      finish: false,
      id: '',
    })
    const loadData = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get('/api/seller/reseller/'+id).then((res) => {
        if(res.data.success){
          state.data = res.data.data;
          state.finish = true;
        }
      })
    }
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    })
    const back = () => {
      window.history.back();
    }
    return {
      state,
      back,
    }
  },
}
</script>